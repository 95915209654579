import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/dental-children/1.jpeg';
import blogd2 from '../../assets/images/2024/dental-children/2.jpeg';
import blogd3 from '../../assets/images/2024/dental-children/3.jpeg';
import blogd4 from '../../assets/images/2024/dental-children/4.jpeg';
import blogd5 from '../../assets/images/2024/dental-children/5.jpeg';
import blogd6 from '../../assets/images/2024/dental-children/6.jpeg';
import blogd7 from '../../assets/images/2024/dental-children/7.jpeg';
import blogd8 from '../../assets/images/2024/dental-children/8.jpeg';
import blogd9 from '../../assets/images/2024/dental-children/9.jpeg';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  The Department of Child Dental Health, Lagos University
                  Teaching Hospital Celebrates Children's Day event with the
                  presentation of Oral Health Awareness at the Dental Clinic.
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The Department of Child Dental Health at Lagos University
                  Teaching Hospital proudly celebrated Children's Day on May
                  27th with a successful event focused on promoting oral health
                  awareness. The celebration at our dental clinic was a
                  memorable occasion, filled with engaging and educational
                  activities designed to teach children the importance of
                  maintaining good oral hygiene.
                  <br />
                  Highlights of the event included:
                  <br />
                  Fun and informative sessions on dental care. Live
                  demonstrations of proper brushing and flossing techniques.
                  Educational talks emphasizing the importance of regular dental
                  check-ups.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Consultative Services Ongoing at the Outreach
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Our team of dedicated dental professionals was thrilled to see
                  the enthusiastic participation from children and their
                  families. The event not only provided valuable knowledge but
                  also ensured that learning about oral health was an enjoyable
                  experience for all attendees.
                  <br />
                  We extend our heartfelt thanks to everyone who joined us in
                  making Children's Day special and educational. Let's continue
                  to promote healthy smiles and foster good oral hygiene habits
                  in our young ones.
                  <br />
                  For more information about our dental health programs, visit
                  our website or contact the Department of Child Dental Health
                  at Lagos University Teaching Hospital. Together, we can ensure
                  bright and healthy smiles for our children!
                </p>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span> */}
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Fig 1: Residents at the department before the program
                  <br />
                  Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach.
                </span> */}
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                  <div className='col-lg-12'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-12'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Residents dispensing medications in advance for the outreach
                </span> */}
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='Medical Outreach to Mashewele Community  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
